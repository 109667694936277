import React, { useCallback, useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import RouterHistory from '../main/RouterHistory';
import { Secured } from '../auth/Secured';
import { useParams } from 'react-router';
import { getForm, updateForm } from '../main/API';
import { Page, PageNav } from '../shared/Page';
import { personalDetails, personalDetailsKey } from "../forms/formSchemas";
import { ConditionalFormForSchema,  } from '@lookinglocal/react-jsonschema-form-extensions';
import { getNumberOfValidPages } from '../forms/formSchemas';

const NHSNumberInput = props => {
  const onChange = (e) => {
    // Only allow numbers and limit to 10 characters
    const value = e.target.value.replace(/[^0-9]/g, '').slice(0, 10);
    props.onChange(value);
  };
  return <Form.Control type="text" inputMode="numeric" data-testid="nhs-number" {...props} onChange={onChange} />;
};

export const DetailsForm = ( {formId, model} ) => {

  const dirtyFields = JSON.parse(model.dirtyFields) || {};

  const handleSubmit = (newValues) => {
    if (model.submittedFields) {
      alert("This assessment has already been submitted and can no longer be amended.");
      return;
    }

    const updatedDirtyFields = { ...dirtyFields, [personalDetailsKey]: newValues };
    const { totalPages, validPages } = getNumberOfValidPages(updatedDirtyFields);
    return updateForm(model.id, {
      ...model,
      dirtyFields: updatedDirtyFields,
      pageCount: totalPages,
      lastValidPage: validPages,
      allPagesValidated: 1,
    })
      .then(() => {
        RouterHistory.push(`/assessment/${formId}`);
      })
      .catch(e => {
        console.error(e);
        alert(JSON.stringify(e));
      });
  };

  const transformErrorsOverride = useCallback(function (errors) {
    return (errors || []).map((error) => {
      if (error.name === 'required') error.message = 'This is required';
      else if (((error.name === 'format') || (error.name === 'pattern')) && (error.params.format === 'email')) {
        error.message = 'This is not a recognised email address';
      }
      else if (((error.name === 'format') || (error.name === 'pattern')) && (error.params.format === 'phone')) {
        error.message = 'This is not a recognised phone number';
      }
      else if ((error.name === 'pattern') && (error.property === '.person.nhsNumber')) {
        error.message = 'This is not a recognised NHS number';
      }
      return error;
    });
  }, []);

  return <ConditionalFormForSchema
    appValidate={personalDetails.appValidate}
    transformErrors={transformErrorsOverride}
    schema={personalDetails.schema}
    rules={personalDetails.rules}
    uiSchema={personalDetails.uiSchema}
    values={dirtyFields[personalDetailsKey] || {}}
    submitBtnLabel={'Save'}
    onSubmit={newValues => handleSubmit(newValues)}
    onCancel={() => RouterHistory.push(`/assessment/${formId}`)}
    appWidgets={{ "nhs-number": NHSNumberInput }}
    isSubmitVisible={true}
    isCancelVisible={true}
  />;
};

const AssessmentSubmitterPage = () => {

  const {formId} = useParams();
  const [model, setModel] = useState(null);

  useEffect(() => {
    getForm(formId).then(response => {
      setModel(response.data.data);
    }).catch(e => {
      console.error(e);
      alert(JSON.stringify(e));
    });
  }, [formId]);

  return (
    <Page>
      <PageNav title="nav.personal_details" goBackUrl={`/assessment/${formId}`} />
      <div className="row space-1">
        <div className="col col-md-8 col-lg-6">
          <div className="bg-white p-3 rounded border">
            {model && <DetailsForm formId={formId} model={model} />}
          </div>
        </div>
      </div>
    </Page>
  );
};

export function AssessmentSubmitterPageSecured () {
  return <Secured onSuccess={AssessmentSubmitterPage}/>;
}
