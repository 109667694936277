import nutrition from './manageNutrition.json';
import relationships from './manageRelationships.json';
import usingFacilities from './usingFacilities.json';
import accessWork from './accessWork.json';
import appropriatelyClothed from './appropriatelyClothed.json';
import maintainingHome from './maintainingHome.json';
import personalHygiene from './personalHygiene.json';
import movingSafely from './movingSafely.json';
import childCare from './childCare.json';
import manageToiletNeeds from './manageToiletNeeds.json';
import personalDetailsSchema from './personalDetails.schema.json';
import personalDetailsRules from './personalDetails.rules.json';
import personalDetailsUiSchema from './personalDetails.uiSchema.json';

export const allSchemas = [
  nutrition,
  personalHygiene,
  manageToiletNeeds,
  appropriatelyClothed,
  movingSafely,
  maintainingHome,
  relationships,
  accessWork,
  usingFacilities,
  childCare];

export const personalDetailsKey = "personalDetails";

export const isValidNHSNumber = (nhsNumber) => {
  // input validation: must be a 10-digit number but can be represented as a string or number
  if (nhsNumber == null || Number.isNaN(Number(nhsNumber)) || nhsNumber.toString().length !== 10) return false;
  if (Number.isInteger(nhsNumber)) {
    nhsNumber = nhsNumber.toString();
  }

  // Step 1: Multiply each of the first 9 numbers by (11 - position indexed from 1)
  // Step 2: Add the results together
  // Step 3: Divide the total by 11 to get the remainder
  const remainder = nhsNumber.split('').slice(0, 9).reduce((acc, digit, index) => acc + (digit * (11 - (index + 1))), 0) % 11;
  // Step 4: Subtract the remainder from 11 to get the checksum
  // Step 5: If the checksum is 11 then use 0, otherwise use the checksum
  const checksum = remainder === 0 ? 0 : 11 - remainder;
  // Step 6: Compare the checksum to the 10th number
  return checksum === Number(nhsNumber[9]);
};

export const personalDetails = {
  appValidate: (formData, errors) => {
    const hasAddress = formData.person.address.single_line_address;
    if (!hasAddress) {
      errors.person.address.addError('Address is required');
    }

    if (formData.person.nhsNumber && !isValidNHSNumber(formData.person.nhsNumber)) {
      errors.person.nhsNumber.addError('Invalid NHS number');
    }

    return errors;
  },
  schema: personalDetailsSchema,
  rules: personalDetailsRules,
  uiSchema: personalDetailsUiSchema
};

export const schemaForArea = (areaSchemaId) => allSchemas.find(it => it.id === areaSchemaId);

export const cleanDirtyFields = (formModel) => {
  const dirtyFields = JSON.parse((formModel && formModel.dirtyFields) || '{}');
  const formValues = typeof dirtyFields === 'object' && dirtyFields ? dirtyFields : {};
  const areaIdsInUse = Object.keys(formValues);

  areaIdsInUse.forEach(area => {
    if (formValues[area].isComplete === false) {
      delete formValues[area];
    }
  });
  return { ...formModel, dirtyFields: JSON.stringify(formValues) };
};

export const getAssessmentStatus = (formModel) => {
  let completedAreas = 0;
  let incompleteAreas = 0;
  const completePersonalDetailsPages = formModel.personalDetails?1:0;

  allSchemas.forEach(schema => {
    if (formModel[schema.id] !== undefined) {
      if (formModel[schema.id].isComplete) completedAreas++;
      else incompleteAreas++;
    }
  });
  return { completedAreas, incompleteAreas, completePersonalDetailsPages };
};

export const allPersonalDetailsPages = 1;
export const minAssessmentAreaRequired = 1;

export const getNumberOfPages = (formModel) => {
  let completedAreas = 0;
  const requiredPages = allPersonalDetailsPages + minAssessmentAreaRequired;
  allSchemas.forEach(schema => {
    if (formModel[schema.id] !== undefined) {
      if (formModel[schema.id].isComplete) completedAreas++;
    }
  });
  if (completedAreas < requiredPages) return requiredPages;
  else return completedAreas + allPersonalDetailsPages;
};

export const getNumberOfValidPages = (model) => {
  const totalPages = getNumberOfPages(model);
  const { completedAreas, completePersonalDetailsPages } = getAssessmentStatus(model);
  const validPages = completedAreas + completePersonalDetailsPages;
  return { totalPages, validPages };
};
