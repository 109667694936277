import React, {useEffect, useState} from 'react';
import {Page, PageNav} from '../shared/Page';
import {Link} from 'react-router-dom';
import {createForm, listForms} from '../main/API';
import {Secured} from '../auth/Secured';
import RouterHistory from '../main/RouterHistory';
import {DownloadPdfBtn} from "../forms/MainFormForPdf";
import {CasualTime, CommonDateTime} from '../shared/Dates';
import {Spinner} from "react-bootstrap";
import {FormattedMessage, useIntl} from "react-intl";
import {suggestedExternalLinks} from '../main/ExternalLinks';
import {ExternalLinkCard} from "../shared/ExternalLink";
import { getNumberOfPages } from '../forms/formSchemas';

function AssessmentListGroupItem({formData, isPrimary = false}) {
  return (<div className="list-group-item d-flex flex-row justify-content-between" data-testid="forms-item">
    <div>
      Assessment #{formData.id}
      <div className="text-muted mr-auto">Date Created: <CommonDateTime date={formData.dateCreated}/></div>
      <div className="text-muted mr-auto">Updated <CasualTime date={formData.lastUpdated}/></div>
      {!!formData.submittedFields && <span className="badge badge-success badge-pill">Submitted</span>}
    </div>
    <div>
      <Link to={`/assessment/${formData.id}/suggestions`} data-testid={`forms-item-${formData.id}-suggestions`} style={{width: 140}} className={`btn btn-outline-secondary mr-2`}>
        <FormattedMessage id="nav.assessment.suggestions" />
      </Link>
      {!formData.submittedFields && <Link to={`/assessment/${formData.id}`} data-testid={`forms-item-${formData.id}-open`} style={{width: 140}} className={`btn ${isPrimary ? 'btn-primary' : 'btn-outline-primary'}`}>
        <FormattedMessage id="nav.assessments.continue" />
      </Link>}
      {!!formData.submittedFields &&
      <DownloadPdfBtn style={{width: 140}} className={`btn ${isPrimary ? 'btn-primary' : 'btn-outline-primary'}`} storedFormData={formData}/>}
    </div>
  </div>);
}

export function AssessmentsPage() {
  const intl = useIntl();
  const SUGGESTION_FOR = {
    LAST: 'LAST',
    ALL: 'ALL'
  };
  const [forms, setForms] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [suggestionsBasedOn, setSuggestionsBasedOn] = useState(SUGGESTION_FOR.LAST);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    listForms()
      .then(response => {
        setForms(response.data.data.filter((x) => x.formTypeCode === 'CSA'));
        setLoading(false);
      })
      .catch(e => {
        console.error(e);
        setLoading(false);
        alert('Could not load assessments');
      });
  }, []);

  useEffect(() => {
    if (!forms.length) {
      setSuggestions([]);
    } else if (suggestionsBasedOn === SUGGESTION_FOR.ALL) {
      setSuggestions(suggestedExternalLinks(forms));
    } else {
      setSuggestions(suggestedExternalLinks([forms[0]]));
    }
  }, [SUGGESTION_FOR.ALL, forms, suggestionsBasedOn]);

  const createNewAssessment = () => {
    const totalPages = getNumberOfPages({});
    setLoading(true);
    createForm("CSA", "Care Support Assessment Form", totalPages)
      .then(resp => {
        const newBlankForm = resp.data.data;
        setForms([...forms, newBlankForm]);
        setLoading(false);
        RouterHistory.push(`/assessment/${newBlankForm.id}`);
      })
      .catch(e => {
        console.error(e);
        setLoading(false);
        alert('Could not setup the assessment');
      });
  };

  return (
    <Page>
      <PageNav title="nav.assessment.title">
        {!!forms.length && <div>
          <button className="btn btn-outline-secondary btn-sm" data-testid="forms-create-btn-secondary" onClick={() => createNewAssessment()}>
            <FormattedMessage id="nav.assessments.new" />
          </button>
        </div>}
      </PageNav>

      {loading && <div className="d-flex align-items-center justify-content-center">
        <Spinner animation={'border'} variant={'primary'}/>
      </div>}

      {!loading && <div className="row align-items-start my-4" data-testid="forms">
        <div className="col-md-12">
          <div className="card mb-4">
            <div className="card-body" data-testid="forms-guidance-main">
              <p>An online supported self-assessment will:</p>
              <ul>
                <li>offer information and advice about resources and support to help you live your life independently</li>
                <li>help you explore your support needs</li>
                <li>help you consider areas where you want to make a change and the support you may need to do this</li>
                <li>help you use your strengths and capabilities in making the changes you want</li>
                <li>guide you with useful animated videos</li>
              </ul>
              <p data-testid="forms-guidance-footer"><span dangerouslySetInnerHTML={{__html: intl.formatMessage({id: "nav.assessment.intro"})}} /> <span dangerouslySetInnerHTML={{__html: intl.formatMessage({id: "nav.assessment.action"})}} /></p>
            </div>
            {!forms.length && <div className="card-body text-center">
              <button className="btn btn-primary btn-lg" data-testid="forms-create-btn-main" onClick={() => createNewAssessment()}>
                <FormattedMessage id="nav.assessments.new" />
              </button>
            </div>}
            {!!forms.length && <div className="list-group list-group-flush">
              {forms.map((form, formIdx) => <AssessmentListGroupItem key={form.id} formData={form} isPrimary={formIdx === 0}/>)}
            </div>}
          </div>
        </div>
      </div>}

      {suggestions && !!suggestions.length && <div data-testid="suggestions">
        <h3 className="h5"><FormattedMessage id="services.user.title"/></h3>
        <div className="row">
          <div className="col-sm-6">
            <p className="text-muted"><FormattedMessage id="services.user.description"/></p>
          </div>
          <div className="col-sm-6 col-lg-4 offset-lg-2">
            <div className="form-group">
              <label htmlFor="basedOnForm" className="sr-only">Based on</label>
              <select className="form-control" id="basedOnForm" data-testid="suggestions-select-filter" value={suggestionsBasedOn} onChange={(event) => setSuggestionsBasedOn(event.target.value)}>
                <option value={SUGGESTION_FOR.LAST}>Based on last assessment</option>
                <option value={SUGGESTION_FOR.ALL}>Based on all assessments</option>
              </select>
            </div>
          </div>
        </div>
        <div className="card-columns">
          {suggestions.map(externalLink => <ExternalLinkCard key={externalLink.url} externalLink={externalLink}/>)}
        </div>
      </div>}

    </Page>
  );
}

export function AssessmentsPageSecured() {
  return <Secured onSuccess={AssessmentsPage}/>;
}
