import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { flavour } from '../config';
import { useAuthContext } from '../auth/AuthContextProvider';
import { FormattedMessage } from 'react-intl';
import { NavDropdown } from "react-bootstrap";
import UserPicture from '../shared/UserPicture';
import {AUTH_VIEW_LOGIN, AUTH_VIEW_SIGNUP} from "../auth/authClient";
import {USE_GOOGLE_TRANSLATE} from "../env";

export function MainNav() {
  const googleTranslateActive = USE_GOOGLE_TRANSLATE;
  const { isAuthenticated, user, logout, loginWithRedirect, viewAccountPage } = useAuthContext();
  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement({ pageLanguage: 'en', layout: window.google.translate.TranslateElement.InlineLayout.VERTICAL }, 'google_translate_element');
  };

  useEffect(() => {
    if (googleTranslateActive === true) {
      let addScript = document.createElement('script');
      addScript.setAttribute('src', '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit');
      document.body.appendChild(addScript);
      window.googleTranslateElementInit = googleTranslateElementInit;
    }
  }, [googleTranslateActive]);

  return (<header id="header" className="mb-5 pb-4">
    <div className="navbar-dark bg-dark shadow">
      <div id="logoAndNav" className="container d-flex justify-content-between">
        <a className="navbar-brand" href="/" aria-label="Homepage">{flavour.brandLogo}</a>
        <nav aria-label="Main navigation" className="navbar navbar-expand px-0">
          <ul className="navbar-nav ml-lg-auto flex-wrap">
            <li className="nav-item d-none">
              <a className="nav-link " href="/">
                Home
              </a>
            </li>
            { isAuthenticated && <li className="nav-item">
              <Link to="/assessment" className="nav-link">
                <FormattedMessage id="nav.assessments" />
              </Link>
            </li>}
            <li className="nav-item">
              <Link to="/help" className="nav-link">
                <FormattedMessage id="nav.help" />
              </Link>
            </li>
            { !isAuthenticated && <>
              <li className="nav-item">
                <button className="btn border-0 nav-link" onClick={() => loginWithRedirect(AUTH_VIEW_LOGIN)}>
                  <FormattedMessage id="general.log_in" />
                </button>
              </li>
              <li className="nav-item ">
                <button className="btn border-0 nav-link" onClick={() => loginWithRedirect(AUTH_VIEW_SIGNUP)}>
                  <FormattedMessage id="general.sign_up" />
                </button>
              </li>
            </>}
            { isAuthenticated && <li>
              <NavDropdown id={"account"} title={<UserPicture className="user-avatar" size="30" email={user.emailProfileEmail || user.email || "guest"} />}>
                <button type="button" className="dropdown-item btn btn-outline-secondary btn-logout" onClick={() => viewAccountPage()}>
                  <FormattedMessage id="nav.account"/>
                </button>
                <button type="button" className="dropdown-item btn btn-outline-secondary btn-logout" onClick={() => logout()}>
                  <FormattedMessage id="general.log_out"/>
                </button>
              </NavDropdown>
            </li>}
            {googleTranslateActive === true && <li className="nav-item"><div id="google_translate_element"></div></li>}
          </ul>
        </nav>
      </div>
    </div>
  </header>);
}
